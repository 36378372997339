import React, { useRef, useState, Fragment, useContext } from "react"
import { navigate } from "gatsby"

import MobileSearch from "../Search/MobileSearch"
import AddMedicineSlidingModal from "./SearchMedsMobile/AddMedicineSlidingModal"

import { AppContext } from "../../context/AppContext"
import { isMedicineAlreadyInCart } from "./services/cart"

const SearchMedsMobile = ({
  pageContext,
  data,
  lookupKeys,
  searchLabel,
  medEnsureState,
  medEnsureDispatch,
}) => {
  const [selectedMedicine, setSelectedMedicine] = useState(null)
  const { dispatch } = useContext(AppContext)
  const inputRef = useRef({ current: { value: "" } })

  const handleOnSelect = (medicine) => {
    if (isMedicineAlreadyInCart(medicine, medEnsureState.medicines))
      dispatch({
        type: "SHOW_TOAST",
        payload: {
          message: `${medicine.DrugName} already in cart`,
          color: "danger",
        },
      })
    else {
      dispatch({
        type: "SHOW_SLIDING_MODAL",
        payload: {
          isCard: true,
          hideCloseButton: false,
          isClosed: false,
          background: {
            opacity: 40,
          },
          content: (
            <AddMedicineSlidingModal
              moduleState={medEnsureState}
              moduleDispatch={medEnsureDispatch}
              addMedicineToCart={addMedicineToCart}
              setSelectedMedicine={setSelectedMedicine}
              selectedMedicine={medicine}
            />
          ),
        },
      })
    }
  }

  const searchComponentProps = {
    placeholder: "Search medicines (i.e. Metformin)",
    label: searchLabel,
    dataSource: {
      type: "graphql",
      data: data,
      lookupKeys: lookupKeys,
    },
    onSelect: handleOnSelect,
    inputRef: inputRef,
    backPath: pageContext.backPath,
  }

  const addMedicineToCart = async (item, qty, intake) => {
    const medicineList = [...medEnsureState.medicines]
    medicineList.push({ ...item, qty: qty, intake: intake })
    await medEnsureDispatch({
      type: "SAVE_MEDICINES",
      payload: medicineList,
    })
    await medEnsureDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: { ...medEnsureState, medicines: medicineList },
    })
    navigate(pageContext.backPath, {
      state: {
        selectedMedicine: item.DrugName,
      },
    })
  }

  return (
    <Fragment>
      <MobileSearch {...searchComponentProps} />
    </Fragment>
  )
}

export default SearchMedsMobile
