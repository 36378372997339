import React, { useContext } from "react"

import SearchMedsMobile from "../../../Epharmacy/SearchMedsMobile"
import useSitelMedensureProducts from "../../Home/hooks/useSitelMedensureProducts"
import { MedEnsureContext } from "../../MedEnsureContext/MedEnsureContext"

const OrderMedsMobile = ({ pageContext }) => {
  const data = useSitelMedensureProducts()
  let dataMap = data.allAirtableallSitelMeProducts.nodes.map(
    (node) => node.data
  )

  const { medEnsureState, medEnsureDispatch } = useContext(MedEnsureContext)

  return (
    <SearchMedsMobile
      pageContext={pageContext}
      data={dataMap}
      lookupKeys={["Brand", "DrugName"]}
      searchLabel="Search medicines"
      medEnsureState={medEnsureState}
      medEnsureDispatch={medEnsureDispatch}
    />
  )
}

export default OrderMedsMobile
